body{
	font-family: "NanumSquare", "Open Sans", sans-serif;
	font-optical-sizing: auto;
	font-size: 14px;
}
.dvh-100 {
    height: 100dvh !important;
}

[data-theme-version="light"] #main-wrapper {
    background-color: #f8fbfd;
}

.logo img{
	height: 70px !important;
}
@media (max-width: 576px) {
	.logo img{
		height: 50px!important;
	}
}

.logo-rankify-icon{
	height: 40px;
	object-fit: contain;
}
.logo-rankify-text{
	height: 40px;
	width: auto;
	max-width: 100%;
	object-fit: contain;
}
@media (max-width: 35.9375rem) {
	img.logo-rankify-icon{
		height: 30px !important;
		width: auto !important;
		max-width: 100% !important;
	}
}
@media (max-width: 720px) {
	.hamburger.is-active .line:first-child {
		transform: translateY(2px) translateX(0px) rotate(-45deg);
	}
	.hamburger.is-active .line:nth-child(3) {
		transform: translateY(-2px) translateX(0px) rotate(-135deg);
	}
}

input[type="password"] {
    font-family: "Open Sans", sans-serif;
}

.color-scrollbar-os{
	scrollbar-color: rgba(111, 133, 147, 0.6) #fff0;
	&::-webkit-scrollbar {
		opacity: 1!important;
    }

}

.hidden-scrollbar-os{
	scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0px; /* Set width of the scrollbar */
        height: 0px;
	    background-color: transparent;
    }
}
table.sticky-table-ranking-keyword > thead > tr > th{
	height: 54px;
}

table.sticky-table-ranking-keyword {
	width: 100%;
	table-layout: fixed;
  }
  
  table.sticky-table-ranking-keyword > thead > tr > th:nth-child(2),
  table.sticky-table-ranking-keyword > tbody > tr > td:nth-child(2) {
	position: sticky;
	z-index: 0;
	left: 0;
  }
  
  table.sticky-table-ranking-keyword.is-scroll:not(.table-fixed-header) > thead > tr > th:nth-child(2) {
	background-color: #f8f9fb !important;
  }
  table.sticky-table-ranking-keyword.is-scroll:not(.table-fixed-header) > tbody > tr > td:nth-child(2){
	background-color: var(--card) !important;
  }
  
  table.sticky-table-ranking-keyword.is-scroll > tbody > tr > td:nth-child(2),
  table.sticky-table-ranking-keyword.is-scroll > thead > tr > th:nth-child(2) {
	z-index: 2;
  }
  
  table.sticky-table-ranking-keyword.is-scroll > tbody > tr > td:nth-child(2)::before,
  table.sticky-table-ranking-keyword.is-scroll > thead > tr > th:nth-child(2)::before {
	position: absolute;
	content: "";
	width: 5px;
	height: calc(100% + 2px);
	background-color: transparent;
	z-index: 5;
	right: 0px;
	top: -1px;
	bottom: -1px;
	box-shadow: rgba(0, 0, 0, 0.21) 4px 0px 6px -2px;
  }



.table-ranking-keyword{
	transition: all 0.3s ease;
 	td {
		color: var(--text-dark);
		padding: 0.5rem 0.5rem !important;
		&.box-sub-row-table{
			background-color: rgba(234, 234, 234, 0.4) !important;
			padding: 30px 36px !important; 
			.table thead tr th:last-child {
				text-align: center !important;
			}
		}
	}
	tr:hover{
		background-color: rgba(250, 250, 250, 1) !important;
	}
}
[data-theme-version="dark"] .table-ranking-keyword{
   tr:hover{
	   background-color: rgba(250, 250, 250, 0.1) !important;
   }
}

table.dataTable.table-fixed-header{
	& thead th {
		border-bottom: 0px solid var(--body-bg);
		background-color: #f8f9fb;
	}
	& thead tr{
		border-bottom-color: var(--border);
	}
	
}
.infinite-scroll-component {
	overflow-y: hidden !important;
    &::-webkit-scrollbar {
        width: 0px; /* Set width of the scrollbar */
        height: 0px;
	    background-color: transparent;
    }
}
.col-ranking-category{
	min-width: calc( 100dvw / 4 );
	@media (max-width: 720px) {
		&{
			min-width: auto;
		}
	}
}

#back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: all 0.5s ease;
    display: none;
    z-index: 1000;
	width: 56px;
	height: 56px;
	border-radius: 28px;
	box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
    // &:hover {
    //     animation: fade-up 1.5s infinite linear;
    // }
	& svg{
		width: 35px;
    	height: 35px;
	}
	@media (max-width: 720px) {
		&{
			bottom: 20px;
			width: 50px;
			height: 50px;
			border-radius: 25px;
		}
		& svg{
			width: 32px;
			height: 32px;
		}
	}
	@media (max-width: 650px) {
		&{
			bottom: 20px;
			width: 40px;
			height: 40px;
			border-radius: 20px;
		}
		& svg{
			width: 30px;
			height: 30px;
		}
	}
}

@keyframes fade-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  75% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

// Begin::Form Filter Category Page
.nav-link-custom-select{
	border: 1px solid var(--border);
	border-radius: 0.5rem;
	overflow: hidden;
	width: 100%;
	// min-width: 300px;
	justify-content: space-between;
    align-items: center;
	transition: all 0.3s ease;
	&:hover{
		// border-color: var(--primary)
	}
	.nav-link{
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		font-size: 0.813rem;
		height: 2.5rem;
		line-height: 20px;
		border-radius: 0;
		padding: 5px;
		&.active{
			background-color: var(--danger);
			border-radius: 0;
			color: white !important;
		}
	}
}
[data-theme-version="dark"] .nav-pills .nav-link {
    color: #fff;
}

.cover-photo-introduction{
	background-size: cover;
    background-position: center;
    min-height: 12.5rem;
    width: 100%;
	background-image: url(../images/introduction/cover.jpg);
}

.image-introduction{
	background-color: rgba(234, 234, 234, 0.49);
    border-radius: 5px;
    width: 100%;
    height: 168px;
    object-fit: cover;
    object-position: center;
}
.metismenu{
	& > li > a {
		border-radius: 10px;
	}
	ul a {
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 10px;
		font-size: 0.875rem !important;
		&:hover{
			background-color: rgba(234, 234, 234, 0.5);
			border-radius: 10px;
		}
	}
	& > li:hover > a{
		background-color: rgba(234, 234, 234, 0.5);
		border-radius: 10px;
	}
	&.metismenu-bottom:not(.mm-active) > li:hover > a{
		background-color: rgba(246, 180, 12, 0.2);
	}
	.mm-active{
		.icon-item-menu-fill{
			path{
				fill: #F6B40C;
			}
		}
		.icon-item-menu-stroke{
			path{
				stroke: #F6B40C;
			}
		}
		.icon-item-menu-stroke-rect{
			path, rect, clipPath, g, defs, circle{
				stroke: #F6B40C;
			}
		}
	}
}

.btn-login-on-header{
	max-height: 36px;
	font-size: 14px!important;
	// flex: 1 0 auto;
    // display: grid;
	// align-items: center;
    // align-content: center;
	// padding: 11px 20px;
    // line-height: 20px;
	border-width: 1px;
	@media (max-width: 576px) {
		max-height: 30px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 13px!important;
	}
}

.btn-warning-custom{
	color: #E7A600;
	background:  rgba(255, 247, 219, 1);
	&:hover{
		color: #E7A600;
		background:  rgba(255, 247, 219, 0.8);
	}
}
.btn-warning-charge{
	color: #fff;
	background:  rgb(246, 180, 12);
	&:hover{
		color: #fff;
		background:  rgba(246, 180, 12, 0.8);
	}
}
.text-color-1{
	color: #6C6F76;
}
.text-color-2{
	color: #47494E;
}
.text-color-3{
	color: #868990;
}
.text-color-4{
	color: #E9EBF1;
}
.text-color-5{
	color: #A7ABB5;
}
.bg-purple-light{	
	background-color: #F6F7FA;
}
[data-theme-version="dark"] {
	.text-color-1, .text-color-2, .text-color-3, .text-color-4, .text-color-5 {
		color: #fff
	}

	.bg-purple-light {
		background-color: rgba(246, 247, 250, 0.3);
	}
}
.form-control-popper-date-picker-range{
	
	.react-datepicker__triangle{
		display: none;
	}
	.react-datepicker{
		font-family: "NanumSquare", "Open Sans", sans-serif;
		border-radius: 8px;
		// background: #FFF;
		box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.20);
		border-width: 0px;
	}
	.react-datepicker__day--selected{
		border-radius: 8px;
		background: #1A1A1C;
	}
	.react-datepicker__week-number--selected {
		border-radius: 8px;
		background-color: #F6B40C;
		color: #fff;
	}
	.react-datepicker__week {
		&:has(.react-datepicker__week-number):has(.react-datepicker__day){
			border: 1px dashed transparent;
			border-radius: 10px;
			transition: all 0.3s ease;
		  }
		&:has(.react-datepicker__week-number):has(.react-datepicker__day:hover), &:has(.react-datepicker__week-number--selected){
		  background-color: #FFF7DB;
		  border-color: #F6B40C;
		}
	}
	.react-datepicker__day--keyboard-selected{
		background-color: #FFF7DB;
		border-radius: 8px;
	}
	.react-datepicker__month-text--keyboard-selected{
		border-radius: 8px;
		background: #1A1A1C;
		color: #fff;
	}
	.react-datepicker__day:hover{
		border-radius: 8px;
	}
	.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
		font-weight: 600;
	}
	.react-datepicker__header {
		border-bottom: 1px solid #F3F3F3;
	}
	.react-datepicker__navigation-icon::before {
		border-width: 2px 2px 0 0;
		border-radius: 2px;
	}

}
[data-theme-version="dark"] .form-control-popper-date-picker-range{
	.react-datepicker, .react-datepicker .react-datepicker__current-month, .react-datepicker .react-datepicker-year-header, .react-datepicker .react-datepicker__day-name, .react-datepicker .react-datepicker__day {
		color: #f6b40e;
	}
	.react-datepicker__month-text--disabled{
		color: #FFF7DB;
	}
	.react-datepicker__navigation-icon::before {
		border-color: #ccc;
	}
}
.custom-react-select-v2{
	&.custom-react-select > div:nth-child(3) {
		height: 2.5rem;
		min-height: 2.5rem;
		// border-color: #141414 !important;
	}
	&.custom-react-select [class*="-indicatorContainer"]{
		padding: 7px;
	}
}
@media only screen and (max-width: 100rem) {
    .nav-control {
        transform: translateY(-50%);
    }
}
@media only screen and (min-width: 721px){
	.menu-toggle .header .header-content, .header .header-content {
		height: 62px;
		background-color: var(--sidebar-bg);
		// width: calc(100% - 232px);
		// box-shadow: 0 3px 6px 0 rgba(206, 212, 218, 0.3);
	}
	// .nav-control {
	// 	transform: translateY(-50%);
	// }
	.nav-header{
		top: 0;
		height: 62px;
		width: 232px;
	}
}
@media only screen and (max-width: 720px){
	.header .header-content, .menu-toggle .header .header-content {
		height: 62px;
		background-color: var(--sidebar-bg);
		width: calc(100%);
	}
	// .nav-control {
	// 	transform: translateY(-50%);
	// }
	.nav-header{
		top: 0;
		height: 62px;
		width: 232px;
	}
}
@media only screen and (max-width: 576px){
	.menu-toggle .header .header-content, .header .header-content {
		height: 62px;
		background-color: var(--sidebar-bg);
	}
	.nav-header{
		top: 0;
		height: 62px;
		width: 100px;
	}
}

.nav-control {
    top: 46%!important;
    transform: translateY(-43%)!important;
    height: 45px!important;
    width: 45px!important;
}

.dlabnav.false {
    z-index: 4;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav {
    position: fixed; 
	top: 62px;
}
[data-sidebar-style="overlay"] .dlabnav {
    box-shadow: 0 0.625rem 0.625rem -0.375rem rgba(0, 0, 0, 0.2);
}

.dlabnav {
	top: 62px;
	height: calc(100dvh - 62px);
	width: 232px;
	// .menu-scroll {
	// 	height: calc(100% - 62px);
	// }
	.dlabnav-scroll {
		overflow-y: auto;
	}
}



.form-height-38{
	height: 2.5rem;
}



.content-body {
    // background-color: #f3f3f3;
}
[data-theme-version="dark"] {
	.custom-tab-1 .nav-link:hover, .custom-tab-1 .nav-link.active {
		background-color: transparent;
		color: #fff;
		border-color: #fff;
	}
}
// .content-body .container-fluid{
//     padding-top: 2rem;
//     padding-right: 2rem;
//     padding-left: 2rem;
// }
.btn-reset-on-category:hover{
	background-color: #e6e6e6;
	border-color: #e6e6e6;
	color: #1A1A1C;
}
[data-theme-version="dark"] .btn-reset-on-category{
	color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}
@media only screen and (min-width: 960px){
	.btn-filter-on-category, .btn-reset-on-category{
		min-width: 200px;
	}
	.menu-scroll{
		min-height: calc(100dvh - 62px);
	}
	#menu.metismenu-bottom{
		margin-bottom: 32px;
	}
	.content-body .container-fluid{
		padding-top: 1.5rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

}
@media only screen and (max-width: 720px){
	.btn-filter-on-category, .btn-reset-on-category{
		min-width: 100px;
		padding: 10px 1rem;
	}
	.menu-scroll{
		min-height: calc(100dvh - 62px);
	}
	#menu.metismenu-bottom{
		margin-bottom: 10px;
	}

}
@media only screen and (max-width: 576px){
	.btn-filter-on-category, .btn-reset-on-category{
		min-width:80px;
		padding: 0.6rem 1rem;
	}
	.menu-scroll{
		min-height: calc(100dvh - 62px);
	}
	#menu.metismenu-bottom{
		margin-bottom: 10px;
	}

}
.modal-content{
	border-radius: 20px;
}

#main-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100dvh;
  }
.card {
    border-radius: 20px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
	.card-header{
		padding: 24px;
		padding-bottom: 20px;
	}
	.card-body{
		padding: 24px;
	}
}
@media only screen and (max-width: 650px){
	.card {
		.card-header{
			padding: 18px;
			padding-bottom: 16px;
		}
		.card-body{
			padding: 18px;
		}
	}
}

.content-body {
	flex: 1;
}

.card-push-point{
	transition: all 0.3s ease;
	&:hover{
		background-color: #F6F7FA;
	}
}

.form-check-input-custom.form-check-input:checked[type="radio"] {
    background-image: none;
    background-color: #fff;
    border-color: #1a1a1c !important;
}
.form-check-input-custom.form-check-input[type="radio"] {
    border-color: #E1E1E1;
	background-color: #FAFAFA;
}
@media only screen and (max-width: 720px){
	.form-check-input:checked[type=radio]:after {
		left: 0px;
        top: 0px;
        transform: translate(30%, 30%);
        width: 10px;
        height: 10px;
        border-radius: 5px;
	}
	.authincation .login-aside{
		background-color: transparent;
		margin-top: 5dvh;
	}
}

@media only screen and (max-width: 576px){
	.authincation .login-aside{
		background-color: transparent;
		margin-top: 7dvh;
	}
	.form-check-input:checked[type=radio]:after {
		left: 0px;
        top: 0px;
        transform: translate(35%, 32%);
        width: 10px;
        height: 10px;
        border-radius: 5px;
	}
}

// Begin::Custom front-size
.dlabnav {
	.metismenu {
		padding-top: 1rem;
		& > li > a {
			padding: 14px 10px !important;
			.nav-text{
				font-size: .875rem!important;
			}
		}
		ul a {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	.metismenu-bottom{
		& > li > a {
			padding: 10px 10px !important;
	}
	}

}

.page-titles{
	padding-top: 12px;
	padding-bottom: 12px;
	padding-right: 1.875rem;
	padding-left: 1.5rem;
	margin-top: 62px!important;
	min-height: 75px;
	& h2, & .h2 {
			font-size: 14px;
			font-weight: 500;
			line-height: 1.5;
			color: var(--danger);
		&.title-page-small{
			font-size: 14px;
    		font-weight: 500;
			line-height: 1.5;
		}
		.text-sub-disabled{
			color: rgba(0, 0, 0, .38)!important;
		}
		&:has(.text-sub-disabled) > .text-sub-active{
			color: var(--danger);
		}
	}
	& .breadcrumb li a{
		font-size: .875rem;
	}
}

[data-sidebar-style="overlay"][data-layout="vertical"] .header .page-titles {
    padding-left: 1.875rem;
}
@media only screen and (min-width: 1240px) {
    [data-sidebar-style="full"] .header, [data-sidebar-style="overlay"] .header {
        width: 100%;
        padding-left: 232px;
    }
}

@media only screen and (max-width: 650px){
	.page-titles{
		min-height: 40px;
	}
}
.btn-search-category{
	max-height: 40px;
	max-width: 80px;
	font-size: 14px!important;
	padding: 0.45rem 1rem;
}
@media only screen and (max-width: 1440px){
	.btn-search-category{
		max-height: 36px;
		padding: 8px 1rem!important;
	}
}


.form-label {
    font-size: .8rem;
}
.table.dataTable thead th, .table.table-font-size.table-responsive thead th{
	font-size: 14px;
	background-color: #f8f9fb;
	font-weight: bold;
	color: #333;
}
.table.dataTable tbody td, .table.table-font-size.table-responsive tbody td{
	font-size: 14px;
	color: #333;
}
.btn-font-size-vote{
	font-size: 14px;
}
.title-table-ranking{
	font-size: 20px;
}

@media only screen and (max-width: 650px){
	.table.dataTable thead th, .table.table-font-size.table-responsive thead th{
		font-size: 13px;
	}
	.table.dataTable tbody td, .table.table-font-size.table-responsive tbody td{
		font-size: 13px;
	}
	.btn-font-size-vote{
		font-size: 13px;
	}
	.title-table-ranking{
		font-size: 18px;
	}
}
// .table-font-size.table-responsive tbody td{
// 	font-size: 0.94rem !important;
// 	color: var(--text-dark);
// }
// Begin::Custom front-size

.card-body {
    padding: 1rem;
}
table.dataTable > thead > tr > th {
    padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

[data-headerbg="color_12"] .header:not(.home):after {
	background-color: var(--sidebar-bg);
}

.nav-control {
	left: 1rem;
	right: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
    margin-left: 0rem;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav {
	left: -232px;
    width: 232px;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .page-titles {
    padding-left: 1.875rem;
}
[data-headerbg="color_12"] .header:not(.home):after {
    background-color: transparent;
}

[data-headerbg="color_12"] .header:not(.home) {
    background-color: transparent;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
    width: 232px;
	z-index: 5;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
    display: block;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
    padding-left: 2rem;
	padding-right: 0;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
    padding-left: 0rem;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .page-titles {
    padding-left: 1.5rem;
}
[data-nav-headerbg="color_12"] .nav-control {
    background: transparent;
}

[data-headerbg="color_12"] .hamburger .line {
    background: var(--danger) !important;
	border-radius: 0px;
}
[data-headerbg="color_12"] .header-profile2 span {
    color: var(--primary);
}
[data-nav-headerbg="color_12"] .header-right .notification_dropdown .nav-link {
    background-color: rgba(210, 210, 210, 0.0);
}
[data-nav-headerbg="color_12"] .header-right svg path {
    fill: rgb(187, 187, 187);
}
.notification_dropdown .dropdown-menu-end {
    min-width: 10rem;
    width: 10rem;
}
.nav-header, .header-content, .header.is-fixed .header-content{
	background-color: var(--sidebar-bg);
}

[data-theme-version="light"] .nav-header, [data-theme-version="light"] .header-content, [data-theme-version="light"] .header.is-fixed .header-content{
	box-shadow: 0 6px 6px -3px rgba(206, 212, 218, 0.3);
}
[data-sidebar-style="overlay"] .dlabnav .metismenu ul a {
	padding-top: 10px;
    padding-bottom: 10px;
}
[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu > li > a, [data-sidebar-style="overlay"] .dlabnav .metismenu > li > a{
	border-radius: 10px;
}
[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu.metismenu-bottom > li:not(.mm-active) > a, [data-sidebar-style="overlay"] .dlabnav .metismenu.metismenu-bottom > li:not(.mm-active) > a{
	border: 1px solid var(--warning);
	color: var(--warning);
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu > li.mm-active > a {
	border-radius: 10px;
    box-shadow: 0 6px 10px 0 rgba(57, 57, 57, 0.2);
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu.metismenu-bottom > li.mm-active > a, [data-sidebar-style="overlay"] .dlabnav .metismenu.metismenu-bottom > li.mm-active > a{
	border: 1px solid var(--primary);
}
.nav-header .brand-logo {
	padding-left: 2rem;
	padding-right: 0;
}
@media only screen and (max-width: 750px){
	.nav-header .brand-logo {
		padding-left: 0rem;
	}
}
@media only screen and (max-width: 576px){
	.nav-header .brand-logo {
		padding-left: 30px;
		padding-right: 0;
	}
}

.nav-tabs-keyword{
	border-bottom: 1px solid #F6B40C;
	.nav-link{
		border-color: #F6B40C;
		color: #F6B40C;
		// border-radius: 0px;
		// margin: 0;
		border-bottom: 0px;
		
		&.active, &:hover{
			background-color: #F6B40C;
			color: #fff;
			// border-top-width: 2px;
    		// border-top-color: #F6B40C;
		}
	}
}

.dlabnav .metismenu .has-arrow:after{
	border-top: 2px solid #C8C8C8;
    border-left: 2px solid #C8C8C8;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    transform: rotate(-135deg) translateY(-50%);
}



.dlabnav .metismenu .mm-active > .has-arrow:after {
	border-top: 2px solid #C8C8C8;
    border-left: 2px solid #C8C8C8;
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    transform: rotate(45deg) translateY(0%);
}
.dlabnav .metismenu ul a:before{
	display: none;
}
.dlabnav .metismenu ul a.mm-active {
    font-weight: 700;
	color: var(--warning);
}

.hamburger .line:nth-child(3) {
    width: 1.375rem;
}

// .active-item-menu{
// 	background-color: red;
// 	color: #fff;
// 	box-shadow: 0 6px 10px 0 rgba(255, 36, 36, 0.2);
// 	border-radius: 10px;
// }